<template>
  <el-dialog :title="title" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="600px" :before-close="dialogClose">
    <el-form @submit.native.prevent ref="form" :model="form" :rules="rules" label-width="110px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" maxlength="50" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="审批单号" prop="auditCode">
        <el-input v-model="form.auditCode" placeholder="请输入审批单号"></el-input>
      </el-form-item>
      <el-form-item label="编号" v-if="showNumber" :required="batchType==11 || batchType==5">
        <el-input v-model="form.number" maxlength="50" placeholder="请输入编号"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="name">
        <el-input v-model="form.name" maxlength="16" placeholder="请输入真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" :prop="phone">
        <el-input v-model="form.phone" maxlength="11" placeholder="请输入电话(7-11位数字)"></el-input>
      </el-form-item>
      <el-form-item :label="moneyTitle" prop="money">
        <el-input placeholder="请填写数量" maxlength="7" v-model="form.money" type="number" @input="changeCheck"></el-input>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" v-debounce="onSubmit">立即生成</el-button>
        <el-button @click="dialogClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "单个发放"
    },
    batchType: Number | String,
    exchangeType: Number | String,
  },
  data() {
    return {
      form: {
        number: "",
        phone: "",
        money: "",
        name: "",
        title: "",
        auditCode: ''
      },
      rules: {
        title: [{ required: true, message: "标题不可为空", trigger: "blur" }],
        auditCode: [{ required: true, message: "审批单号不可为空", trigger: "blur" }],
        name: [{ required: true, message: "姓名不可为空", trigger: "blur" }],
        money: [
          { required: true, message: "积分数量不可为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "手机号码不可为空", trigger: "blur" },
        ]
      }
    };
  },
  computed: {
    showNumber() {
      return [11, 6, 5].includes(Number(this.batchType))
    },
    phone() {
      if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(Number(this.batchType))) {
        return 'phone'
      } else {
        return ''
      }
    },
    moneyTitle() {
      if ([0, 1, 2].includes(Number(this.batchType))) {
        return '发放金额（元）'
      } else {
        return '发放积分数量（1元=1积分）'
      }
    }
  },
  methods: {
    changeCheck() {
      let exp = /^(\-)*(\d+)\.(\d\d).*$/;
      this.form.money = this.form.money.replace(exp, "$1$2.$3");
    },
    dialogClose() {
      this.$emit("update:show", false);
    },
    onSubmit() {
      if (this.batchType == 11 && !this.form.number) {
        this.$message.warning('编号不能为空')
        return
      }
      let form = JSON.parse(JSON.stringify(this.form));
      this.$refs["form"].validate(valid => {
        if (valid) {
          form["codeType"] = this.batchType;
          this.$emit('submit', form)
        } else {
          this.$message.error({
            message: "请检查重试",
            duration: 1500
          });
          return false;
        }
      });
    }
  }
};
</script>