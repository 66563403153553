<template>
  <el-dialog :title="title" :visible.sync="show" :close-on-click-modal="false" width="1040px" :before-close="dialogClose">
    <div v-loading="loading">
      <el-form style="text-align:left" label-width="120px" size="mini">
        <el-form-item label="标题" required>
          <el-input v-model="grantTitle" style="width:500px" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="审批单号" required>
          <el-input v-model="auditCode" style="width:500px" placeholder="请输入审批单号"></el-input>
        </el-form-item>
        <OrganizationSelect v-model="submitOrg" required :showAll="false" style="width:620px" :isCheck="false" v-if="[0,1,2,5,6].includes(batchType)"></OrganizationSelect>
        <el-form-item label="Excel模板">
          <a style="margin:0px;" :href="downloadHref">下载模板</a>
        </el-form-item>
        <el-form-item label="导入模板">
          <el-checkbox v-model="checked" :false-label="0" :true-label="1" v-show="batchType == 5">是否校验错误数据</el-checkbox>
          <el-upload :class="fileName?'upload-demo':''" ref="upload" accept=".xls, .xlsx" :action="url + uploadUrl" :before-remove="handleTip" :on-remove="handleRemove" :on-success="handleSuccess" :headers="headers" :on-progress="onProgress" :on-error="onError" :limit="1">
            <el-button size="small" type="primary">点击上传</el-button>
            <span slot="tip" style="margin-left:20px" v-if="!fileName">只允许上传Excel文件</span>
          </el-upload>
        </el-form-item>
        <el-form-item v-show="listObj && listObj.totalPersonnel">
          <span>总人数：{{listObj.totalPersonnel}} 人</span>
          <span style="margin-left:40px">总金额：{{listObj.totalAmount}} 元</span>
        </el-form-item>
        <el-form-item v-if="failList && failList.length && batchType==11">
          <el-row>
            <el-col :span="8">
              共有{{list.length}}条数据，错误{{failList.length}}条数据
            </el-col>
            <el-col :span="16">
              <el-checkbox v-model="value1">显示错误数据</el-checkbox>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item v-if="batchType==6 || batchType==5">
          <el-row>
            <el-col :span="8" v-if="repeatList && repeatList.length">
              <el-checkbox v-model="value2" @change="checkboxChange(1)">显示重复数据({{repeatList.length}})</el-checkbox>
            </el-col>
            <el-col :span="8" v-if="currentErrorList && currentErrorList.length">
              <el-checkbox v-model="value3" @change="checkboxChange(2)">显示本次清单错误({{currentErrorList.length}})</el-checkbox>
            </el-col>
            <el-col :span="8" v-if="historyErrorList && historyErrorList.length">
              <el-checkbox v-model="value4" @change="checkboxChange(3)">显示对比历史错误({{historyErrorList.length}})</el-checkbox>
              <el-tooltip effect="dark" :content="batchType==5?'历史错误中相同手机号不同名称、编码、机构的数据':'本次清单中相同手机号不同名称的数据'" placement="bottom">
                <i class="el-icon-question" style="margin-left:8px"></i>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div v-if="value4" :key="value4">
        <el-table border :data="historyErrorList" size="mini" height="350px">
          <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
          <el-table-column label="本次导入数据">
            <el-table-column prop="importErrorList.number" align="center" label="编码" v-if="showNumber"></el-table-column>
            <el-table-column prop="importErrorList.userName" align="center" label="客户名称"></el-table-column>
            <el-table-column prop="importErrorList.phone" align="center" label="手机" v-if="showPhone"></el-table-column>
            <el-table-column prop="importErrorList.amount" align="center" label="面额"></el-table-column>
            
          </el-table-column>
          <el-table-column label="历史数据">
            <el-table-column prop="userErrorList.number" align="center" label="编码" v-if="showNumber"></el-table-column>
            <el-table-column prop="userErrorList.customerName" align="center" label="客户名称"></el-table-column>
            <el-table-column prop="userErrorList.orgName" align="center" label="所属机构" show-overflow-tooltip></el-table-column>
            <el-table-column prop="userErrorList.usedPhone" align="center" label="手机" v-if="showPhone"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div v-else :key="value4">
        <el-table border stripe :data="value1 ? failList : value2 ? repeatList : value3 ? currentErrorList :list" size="mini" height="350px">
          <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
          <el-table-column prop="number" align="center" label="编码" v-if="showNumber"></el-table-column>
          <el-table-column prop="userName" align="center" label="客户名称"></el-table-column>
          <el-table-column prop="phone" align="center" label="手机" v-if="showPhone"></el-table-column>
          <el-table-column prop="amount" align="center" label="面额"></el-table-column>
          <el-table-column prop="idCode" align="center" label="身份证"></el-table-column>
          <el-table-column prop="bankCard" align="center" label="卡号"></el-table-column>

        </el-table>
      </div>
      <el-button style="margin:20px 10px 0 10px;" type="primary" v-debounce="sureImport">确认导入</el-button>
      <el-button type="info" @click="dialogClose">关闭窗口</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { baseURL } from "@/utils/http";
import { getCookies } from "@/utils/utils";
import OrganizationSelect from '@/components/OrganizationSelect.vue'

export default {
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "批量发放"
    },
    batchType: Number | String,
    exchangeType: Number | String,
  },
  components: { OrganizationSelect },
  data() {
    return {
      checked: 0,
      loading: false,
      grantTitle: '',
      auditCode: '',
      submitOrg: getCookies("organizationId"),
      value1: false,
      value2: false,
      value3: false,
      value4: false,
      list: [],
      url: baseURL,
      submitStatus: false,
      headers: {
        "x-token": getCookies("x-token")
      },
      uploadData: {
        codeType: 6,
        sysId: getCookies("sysId"),
        organizationId: getCookies("organizationId")
      },
      failList: [],
      repeatList: [],
      historyErrorList: [],
      currentErrorList: [],
      fileUrl: '',
      listObj: {},
      fileName: ''
    };
  },
  computed: {
    showNumber() {
      return [6, 5, 11].includes(Number(this.batchType))
    },
    showPhone() {
      return [6, 5, 2, 1, 12].includes(Number(this.batchType))
    },
    downloadHref() {
      let list = [
        'https://template.gongxingtech.com/现油-核销码导入Excel.xlsx',
        'https://template.gongxingtech.com/现油-前三后四导入Excel.xlsx',
        'https://template.gongxingtech.com/现油-直接充值导入Excel.xlsx',
        '',
        'https://template.gongxingtech.com/积分-核销码导入Excel.xlsx',
        'https://template.gongxingtech.com/积分-前三后四导入Excel.xlsx',
        'https://template.gongxingtech.com/积分-直接发放导入Excel.xlsx',
      ]
      list[11] = 'https://template.gongxingtech.com/积分-工号发放导入Excel.xlsx'
      list[12] = 'https://template.gongxingtech.com/现油-直接充值导入Excel.xlsx'
      return list[this.batchType]
    },
    uploadUrl() {
      let str = ''
      if (this.batchType == 4 || this.batchType == 0) {
        str = '/cancelCode/importCancelCode'
      } else if (this.batchType == 6 || this.batchType == 11 || this.batchType == 2 || this.batchType == 1 || this.batchType == 12) {
        str = `/wlynIntegral/importAutonym?orgId=${this.submitOrg}`
      } else if (this.batchType == 5) {
        str = `/wlynIntegral/importHideIntegralInfo?orgId=${this.submitOrg}&isCheck=${this.checked}`
      }
      console.log(str)
      return str
    }
  },
  methods: {
    checkboxChange(t) {//切换
      if (t == 1) {
        this.value3 = false
        this.value4 = false
      } else if (t == 2) {
        this.value2 = false
        this.value4 = false
      } else if (t == 3) {
        this.value3 = false
        this.value2 = false
      }
    },
    delItem(index) {
      if (this.value1 && this.batchType == 11) {
        this.failList.splice(index, 1);
      } else {
        this.list.splice(index, 1);
      }
    },
    sureImport() {
      if (!this.grantTitle) {
        this.$message.warning('请填写标题')
        return
      }
      if (!this.auditCode) {
        this.$message.warning('请填写审批单号')
        return
      }

      if (!this.list || !this.list.length) {
        this.$message({
          type: "error",
          message: "暂无数据，请上传模版后再次确认提交！",
          duration: 1500
        });
        return false;
      }
      if (this.failList && this.failList.length && this.batchType == 11) {
        this.$message.warning('表格包含错误数据，请检查后重试')
        return
      }
      if (this.repeatList && this.repeatList.length) {
        this.$message.warning('表格包含重复数据，请检查后重试')
        return
      }
      if (this.currentErrorList && this.currentErrorList.length && ![1, 5].includes(this.batchType)) {
        this.$message.warning('表格包含错误数据，请检查后重试')
        return
      }
      if (this.historyErrorList && this.historyErrorList.length && ![1, 5].includes(this.batchType)) {
        this.$message.warning('表格包含历史错误数据，请检查后重试')
        return
      }
      this.$confirm('确认导入?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$emit('submit', this.list, this.grantTitle, this.auditCode, this.fileUrl, this.submitOrg)
      }).catch(() => {
      })
    },
    dialogClose() {
      this.$emit("update:show", false);
    },
    handleSuccess(e, file) {
      if (e.code == 200) {
        this.fileName = file.name;
        let data = e.data || {}
        this.list = Array.isArray(data) ? data : data.list;
        if (this.batchType == 6 || this.batchType == 11 || this.batchType == 2 || this.batchType == 1 || this.batchType == 5) {
          this.fileUrl = data.fileUrl || ''
          this.failList = data.failList || []
          this.repeatList = data.repeatList || []
          this.historyErrorList = data.historyErrorList || []
          this.currentErrorList = data.currentErrorList || []
          this.listObj = data
        }
      } else {
        this.$refs['upload'].clearFiles()
      }
      // else if (e.code == 30003) {

      //   this.loading = false
      //   this.$message({
      //     message: e.msg || e.error_msg || e.message,
      //     type: "success"
      //   });
      // }
      this.loading = false
      this.$message({
        message: e.msg || e.error_msg || e.message,
        type: e.code == 200 ? "success" : 'error'
      });
    },
    handleTip(e) {
      return this.$confirm('确定删除该文件？')
    },
    handleRemove() {
      this.fileUrl = ''
      this.failList = []
      this.repeatList = []
      this.historyErrorList = []
      this.currentErrorList = []
      this.listObj = {}
      this.list = []
      this.value1 = false
      this.value2 = false
      this.value3 = false
      this.value4 = false
      this.fileName = ''
      this.$message.success('删除成功')
    },
    onProgress() {
      this.loading = true
    },
    onError() {
      this.loading = false
    },
  }
};
</script>
<style scoped>
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 12px !important;
}
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  /* position: relative;
  display: inline-block; */
  width: 690px;

  height: 300px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
.upload-demo /deep/ .el-upload {
  display: none;
}
</style>